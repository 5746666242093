
<template>
    <div>
            <v-container class="my-2">
                    <v-layout row wrap px-0 mb-4>
                        <v-flex xs12>
                            <v-card class="mx-1 px-0" style="background-color:transparent!important" flat>
                                <v-card-title class="px-0">
                                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.customers.list_path">
                                        <template v-slot:item="{ item }">
                                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                                {{ item.title.toUpperCase() }}
                                            </v-breadcrumbs-item>
                                        </template>
                                    </v-breadcrumbs>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn class="float-right" color="primary" icon  @click="exportExcel">
                                        <v-icon>mdi-file-excel</v-icon>
                                    </v-btn> -->
                                    <!-- <v-btn class="float-right" color="primary" icon  to="/add-new-customers">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn> -->
                                </v-card-title>
                            </v-card>
                        </v-flex>
                    </v-layout>
                <form @submit.prevent="addCustomers" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_name" type="text" :label="$store.getters.language.data.customers.customer_name" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_bio" type="textarea" :label="$store.getters.language.data.customers.customer_bio" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_about" type="textarea" :label="$store.getters.language.data.customers.customer_about" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_logo" type="textarea" :label="$store.getters.language.data.customers.customer_logo" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_join_date" type="date" :label="$store.getters.language.data.customers.customer_join_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_exp_date" type="date" :label="$store.getters.language.data.customers.customer_exp_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_background_color" type="text" :label="$store.getters.language.data.customers.customer_background_color" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_text_color" type="text" :label="$store.getters.language.data.customers.customer_text_color" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                        <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['true','false']" v-model="customers.customer_is_active" dense  filled outlined  item-text="customer_is_active"
                                    item-value="customer_is_active" :return-object="false" :label="$store.getters.language.data.customers.customer_is_active">
                                </v-select>
                        </v-flex> -->
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.customers.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card flat>
            <v-card-title primary-title>
                Customers List
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers"  v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="customer_id">
                        <template v-slot:[`item.customer_logo`]="{ item }">
                            <div>
                                <UploadBtn :table="{
                                        table_name: 'customers',
                                        column_name: 'customer_logo',
                                        table_key: 'customer_id',
                                        table_key_value: item.customer_id
                                    }" :isImage="item.customer_logo ? true : false"
                                    :imgName="item.customer_logo"
                                    :height="50"
                                    :width="50"
                                    :quality="70"
                                    :isAvatar="true"
                                @uploaded="uploaded" />
                            </div>
                        </template>
                        <template v-slot:[`item.customer_join_date`]="{ item }">
                            <div>
                                {{   moment(item.customer_join_date).format('YYYY-MM-DD') }}
                            </div>
                        </template>
                        <template v-slot:[`item.customer_exp_date`]="{ item }">
                            <div>
                                {{   moment(item.customer_exp_date).format('YYYY-MM-DD') }}
                            </div>
                        </template>
                        <template v-slot:[`item.customer_is_active`]="{ item }">
                            <div>
                                <v-chip 
                                    :color="item.customer_is_active == 'true' ? 'green' : 'red'"
                                    text-color="white"
                                    >
                                    {{item.customer_is_active == 'true' ? 'Active' : 'Inactive'}}
                                </v-chip>
                            </div>
                        </template>
                    <template v-slot:[`item.customer_id`]="{ item }">
                        <div>
                            <v-btn outlined small class="mx-1" :to="'/@/'+item.customer_id" target="_blank">View</v-btn>
                            <v-btn outlined small :to="'/customers/'+item.customer_id">Links</v-btn>
                            <v-btn icon :to="'/customers-list/'+item.customer_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-delete-outline </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        {{$store.getters.language.data.customers.delete_question}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text class="elevation-0">
                                            {{$store.getters.language.data.customers.cancel_btn}}
                                        </v-btn>
                                        <v-btn color="error" class="elevation-0" @click="deleteCustomers(item.customer_id)">
                                            {{$store.getters.language.data.customers.yes_btn}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/customers.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    import moment from 'moment';
    
    import UploadBtn from '../../components/UploadBtn.vue';
    export default {
        
        components: {
            UploadBtn
        },
        data() {
            return {
                customers: {},
                moment: moment,
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                
                selected_customers : {},
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.customers.customer_name,
                            align: 'start',
                            sortable: true,
                            value: 'customer_name',
                        },
                        // { 
                        //     text: this.$store.getters.language.data.customers.customer_bio,
                        //     align: 'start',
                        //     sortable: true,
                        //     value: 'customer_bio',
                        // },
                        // { 
                        //     text: this.$store.getters.language.data.customers.customer_about,
                        //     align: 'start',
                        //     sortable: true,
                        //     value: 'customer_about',
                        // },
                        { 
                            text: this.$store.getters.language.data.customers.customer_logo,
                            align: 'start',
                            sortable: true,
                            value: 'customer_logo',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_join_date,
                            align: 'start',
                            sortable: true,
                            value: 'customer_join_date',
                        },
                        { 
                            text: this.$store.getters.language.data.customers.customer_exp_date,
                            align: 'start',
                            sortable: true,
                            value: 'customer_exp_date',
                        },
                        // { 
                        //     text: this.$store.getters.language.data.customers.customer_background_color,
                        //     align: 'start',
                        //     sortable: true,
                        //     value: 'customer_background_color',
                        // },
                        // { 
                        //     text: this.$store.getters.language.data.customers.customer_text_color,
                        //     align: 'start',
                        //     sortable: true,
                        //     value: 'customer_text_color',
                        // },
                        { 
                            text: this.$store.getters.language.data.customers.customer_is_active,
                            align: 'start',
                            sortable: true,
                            value: 'customer_is_active',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'customer_id',
        }
                ],
            }
        },
        computed: {
            page(){
                const pageKey = 'CUSTOMERS'
                return {}
            }
        },
        mounted(){
            const nextYearDate = moment().add(1, 'years').format('YYYY-MM-DD')
            this.customers.customer_exp_date = nextYearDate
            this.readCustomers();
        },
        methods: {
            uploaded(data){
                if(data.new_data){
                    this.readCustomers();
                }
            },
            exportExcel(){
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {customer_id : item.customer_id,customer_name : item.customer_name,customer_bio : item.customer_bio,customer_about : item.customer_about,customer_logo : item.customer_logo,customer_join_date : item.customer_join_date,customer_exp_date : item.customer_exp_date,customer_background_color : item.customer_background_color,customer_text_color : item.customer_text_color,customer_is_active : item.customer_is_active,
                    }
                    result.push(obj)
                }
				var f = xlsx.utils.json_to_sheet(result)
				var wb = xlsx.utils.book_new()
				xlsx.utils.book_append_sheet(wb, f, 'sheet')
				xlsx.writeFile(wb, result.length+' rows of '+'Customers.xlsx')
            },
            addCustomers() {
                this.loading_btn = true
                this.customers.customer_is_active = 'false'
                requests.createCustomers(this.customers).then(r => {
                    if (r.status == 200) {
                        this.customers = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'Customers Added',
                            color: 'success'
                        }
                        const nextYearDate = moment().add(1, 'years').format('YYYY-MM-DD')
            this.customers.customer_exp_date = nextYearDate
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Customers',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteCustomers(customer_id) {
                requests.deleteCustomers(customer_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.customer_id != customer_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Customers Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteCustomersList() {
                let ids = this.selected_rows.map(m => m.customer_id)
                requests.deleteCustomersList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.customer_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' Customers Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readCustomers() {
                this.loading = true
                requests.getAllCustomers().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.customers
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Customers',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Customers',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectCustomers(i) {
                this.selected_customers = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    