
import axios from 'axios';
export default {
    
    async createCustomers(params)  {
        return await axios.post(`customers/create` , params)
    },
    async createCustomersList(params)  {
        return await axios.post(`customers/create/list` , params)
    },
    async updateCustomersColumn(column , value , data)  {
        return await axios.put(`customers/update_list?${column}=${value}` , data)
    },
    async deleteCustomersList(list)  {
        return await axios.delete(`customers/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportCustomers(column , value)  {
        return await axios.get(`customers/report?${column}=${value}`)
    },
    async getAllCustomers()  {
        return await axios.get(`customers/all`)
    },
    async getOneCustomers(customer_id)  {
        return await axios.get(`customers/all/${customer_id}`)
    },
    async getCustomersByColumn(column , value)  {
        return await axios.get(`customers/filter?column=${column}&value=${value}`)
    },
    async deleteCustomers(customer_id)  {
        return await axios.delete(`customers/delete/${customer_id}`)
    },
    async updateCustomers(customer_id , params)  {
        return await axios.put(`customers/update/${customer_id}` , params)
    }
}